// extracted by mini-css-extract-plugin
export var breadcrumb = "styles-module--breadcrumb--6wt3R";
export var breakme = "styles-module--breakme--oRD5Y";
export var content = "styles-module--content--c1vHL";
export var header = "styles-module--header--yhdx7";
export var holder = "styles-module--holder--AIMBU";
export var howHolder = "styles-module--howHolder--w9lNl";
export var image = "styles-module--image--XkGAe";
export var kaynakca = "styles-module--kaynakca--Aj916";
export var kidsHolder = "styles-module--kidsHolder--PL3JI";
export var listHolder = "styles-module--listHolder--cxJUY";
export var productHolder = "styles-module--productHolder--IhovL";
export var rightCoughHolder = "styles-module--rightCoughHolder--bwzCc";
export var typesHolder = "styles-module--typesHolder--4DsyB";
export var whatHolder = "styles-module--whatHolder--8D06b";
export var whyHolder = "styles-module--whyHolder--9PduE";