import * as React from "react";
import { useEffect } from "react";
import { PageProps } from "gatsby";
import * as styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Breadcrumb } from "../../../components/breadcrumb/breadcrumb";
import Layout from "../../../components/layout/layout";
import Container from "../../../components/container/container";
import Heading from "../../../components/basic-components/heading/heading";
import { ProductBar } from "../../../components/product-bar/product-bar";
import { Level } from "react-accessible-headings";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Helmet } from "react-helmet";
import blogImage from "../../../images/cozum/balgam-nasil-atilir/Content-image.webp";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const CoughPage: React.FC<PageProps> = ({ location }: PageProps) => {
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, []);
  return (
    <Layout
      stickyHeader={false}
      seoConfig={{
        title: "Balgam Nasıl Atılır?",
        description:
          "Balgam Nasıl Atılır, Nedenleri Nelerdir? gibi soruların yanıtını içeriğimizi okuyarak öğrenmek için sayfamızı ziyaret edin!",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context":"https://schema.org",
                "@type":"BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Öksürük","item": "https://www.bisolnatur.com.tr/oksuruk/"},
                  {"@type": "ListItem","position": 3,"name": "Balgam Nasıl Atılır?","item": "https://www.bisolnatur.com.tr/oksuruk/balgam-nasil-atilir/"}
                ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${location.href}"
              },
              "headline": "Balgam Nasıl Atılır?",
              "image": "${location.origin + blogImage}",  
              "author": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "url": "${location.origin}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${location.origin}/logo.svg"
                }
              },
              "datePublished": "2022-01-27",
              "dateModified": "2022-01-27"
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Balgam Nedir?","acceptedAnswer":{"@type":"Answer","text":"Balgam, çeşitli solunum yolu hastalıklarının seyri sırasında boğazda takılan yoğun mukus salgısı olarak tanımlanabilir. Birçok insan rahatsızlık esnasında balgam şikayetinden yakınır. Balgam haline gelen mukus salgısı normalde solunum yollarının desteklenmesinden ve korunmasından sorumludur. Mukusun bu yapıların iç çeperinde oluşturduğu katman ağız, burun, boğaz, sinüsler ve akciğerleri koruyucu özellik gösterir. Yapışkan yapıdaki mukus bu özelliği sayesinde toz, alerjen maddeler ve virüsleri yakalayabilme kapasitesine sahiptir."}},{"@type":"Question","name":"Balgamlı Öksürük Nedenleri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"<ul><li>Bronşit</li><li>Zatürre</li><li>KOAH</li><li>Kistik Fibrozis</li><li>Astım</li></ul>"}}]}
          `}
        </script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <div className={classNames(styles.header)}>
          <StaticImage
            className={styles.image}
            src="../../../images/cozum/balgam-nasil-atilir/Content-image.webp"
            alt="Öksüren kadın görseli"
            width={1680}
            height={450}
          />
          <Container>
            <Heading>Balgam Nasıl Atılır?</Heading>
            <ul>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgam-nedir"
                  title="Balgam Nedir?"
                >
                  Balgam Nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgamli-oksuruk-nedir"
                  title="Balgamlı Öksürük Nedir?"
                >
                  Balgamlı Öksürük Nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgamli-oksuruk-nedenleri-nelerdir"
                  title="Balgamlı Öksürük Nedenleri Nelerdir?"
                >
                  Balgamlı Öksürük Nedenleri Nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#balgam-nasil-atilir"
                  title="Balgam Nasıl Atılır?"
                >
                  Balgam Nasıl Atılır?
                </AnchorLink>
              </li>
            </ul>
          </Container>
        </div>
      </Container>
      <Container>
        <div className={styles.holder}>
          <p id="balgam-nedir" className="extra-bold">
            Balgam, başta akciğerler olmak üzere alt solunum yollarında
            sentezlenen mukusu tanımlar. Bu mukus salgısı çeşitli mikrop ve
            maddenin akciğerlere ulaşmasını ve enfeksiyon oluşturmasını
            engelleyici özellik gösterir. Mukus sadece alt solunum yollarına
            özgü bir salgı değildir. Boğaz, ağız, burun ve sinüslerden oluşan
            üst solunum yollarında ve sindirim kanalında da mukus sentezi
            meydana gelir. Bu salgı tipik olarak berrak ve akışkan özelliklere
            sahiptir. Grip veya diğer enfeksiyon hastalıkları sırasında mukusun
            renk ve yoğunluğunda değişiklikler meydana gelebileceği için
            bilinçli olunması gerekir.
          </p>
          <section className={styles.kidsHolder}>
            <Level>
              <h2 className="orange underline">Balgam Nedir?</h2>
              <p>
                Balgam, çeşitli solunum yolu hastalıklarının seyri sırasında
                boğazda takılan yoğun mukus salgısı olarak tanımlanabilir.
                Birçok insan rahatsızlık esnasında balgam şikayetinden yakınır.
                Balgam haline gelen mukus salgısı normalde solunum yollarının
                desteklenmesinden ve korunmasından sorumludur. Mukusun bu
                yapıların iç çeperinde oluşturduğu katman ağız, burun, boğaz,
                sinüsler ve akciğerleri koruyucu özellik gösterir. Yapışkan
                yapıdaki mukus bu özelliği sayesinde toz, alerjen maddeler ve
                virüsleri yakalayabilme kapasitesine sahiptir.
              </p>
              <p>
                Sağlıklı bireylerde mukusun yoğunluğu düşüktür. Hastalıklar veya
                çeşitli maddelere yoğun maruziyet ile birlikte mukus salgısı bu
                maddeleri yakalayarak daha yoğun bir hale gelir. Bu yoğunlaşma
                ve balgam oluşma süreci aslında solunum sisteminin doğal bir
                koruyucu mekanizması olsa da bazı bireylerde oluşan balgam bu
                sistemin doğru şekilde fonksiyon göstermesini etkileyecek
                boyutta rahatsız edici olabilir.
                <br />
                <br />
              </p>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/balgam-nasil-atilir/img.webp"
                alt="Metroda öksüren kadın görseli"
                width={792}
                height={430}
              />
              <Level>
                <Heading id="balgamli-oksuruk-nedir">
                  Balgamlı Öksürük Nedir?
                </Heading>
              </Level>
              <p>
                Öksürük vücudun bir diğer doğal savunma refleksidir. Hava
                yollarının temizliğinin tam olarak gerçekleştirilmesi amacıyla
                oluşan bir reaksiyondur. Öksürük sayesinde solunum sisteminde
                yer alan mukus, alerjen ve sigara dumanı gibi maddelerin
                uzaklaştırılması sağlanarak enfeksiyon hastalığı gelişiminin
                önüne geçilir. Öksürük kendi içerisinde kuru ve prodüktif (salgı
                içeren) Öksürük olmak üzere iki grupta incelenir. Prodüktif
                öksürükte bulunan salgı genellikle akciğerlerden
                uzaklaştırılmaya çalışılan balgamdır.
              </p>
              <p>
                Öksürük ile birlikte akciğerlerde çeşitli kimyasal maddelerin ve
                mikropların bu bölgedeki hassas dokulara zarar vermesinin önüne
                geçilebilir. Burun ve sinüs yapıları bir günde yaklaşık olarak 1
                litre mukus salgısı sentezleyebilme özelliğine sahiptir. Bu
                yapılara ek olarak boğaz ve akciğerler de mukus üretir. Bu
                bölgelerde üretilen salgı miktarında özellikle alerjik durumlar
                ve grip gibi bulaşıcı hastalıklar sırasında bir artış meydana
                gelir. Dolayısıyla balgamlı öksürüğü olan kişilerde altta yatan
                durum büyük ihtimalle bir solunum yolu enfeksiyonu veya alerjik
                bir hadisedir.
              </p>
            </Level>
          </section>
          <section
            id="balgamli-oksuruk-nedenleri-nelerdir"
            className={styles.typesHolder}
          >
            <Level>
              <Level>
                <Heading>Balgamlı Öksürük Nedenleri Nelerdir? </Heading>
              </Level>
              <p>
                Balgamlı öksürük nedenleri arasında başta bakteri ve virüs gibi
                mikroorganizmalar nedeniyle oluşan nezle ve grip gibi enfeksiyon
                hastalıkları yer alır. Solunum yollarının içe bakan tarafı
                boyunca bir koruyucu mukus tabakası bulunur. Bu mukus tabakası
                hava yollarının nemlendirilmesi ve korunması gibi işlevlere
                sahiptir. Grip ve nezle gibi üst solunum yolları hastalıkları
                dışında diğer çeşitli rahatsızlıklarda da balgamlı öksürük
                şikayeti meydana gelebilir. Eğer oluşan balgamlı öksürük birkaç
                haftadan daha uzun bir süre boyunca devam ediyorsa altta yatan
                neden bronşit, pnömoni (zatürre), kronik obstrüktif akciğer
                hastalığı (KOAH), kistik fibrozis veya astım gibi bir
                rahatsızlık olabileceği için dikkatli olunmalıdır.
              </p>
              <Level>
                <Level>
                  <Heading>Bronşit </Heading>
                </Level>
              </Level>
              <p>
                Bronşit, bronş adı verilen ve akciğere solunan havanın
                taşınmasından sorumlu kanallarda meydana gelen inflamatuar
                (iltihabi) bir durumu ifade eder. Akut (ani başlangıçlı) bronşit
                genellikle viral bir hastalık olarak kabul edilir. Daha uzun
                süreli bir seyir izleyen kronik bronşit ise genellikle tütün
                kullanımına bağlı olarak ortaya çıkar. Kronik bronşit iki yıllık
                bir süre zarfında 3 ay boyunca devam eden balgamlı öksürük
                şikayetine neden olabilir.
              </p>
              <Level>
                <Level>
                  <Heading>Pnömoni (Zatürre) </Heading>
                </Level>
              </Level>
              <p>
                Halk dilide “zatürre” olarak bilinen pnömoni hastalığı
                akciğerlerde bakteri, virüs ya da mantarlara bağlı oluşan
                enfeksiyon halini ifade eder. Pnömoninin seyri orta düzeyden
                yaşamı tehdit edici boyutlara varana kadar değişkenlik
                gösterebileceği için dikkat etmek gerekir. Nefes alıp vermede
                zorlanma, kalp atışlarının hızlanması, ateş, halsizlik, üşüme,
                titreme ve iştah kaybı, pnömoni gelişimi diğer belirtileri
                oluştururlar.
              </p>
              <Level>
                <Level>
                  <Heading>Kronik Obstrüktif Akciğer Hastalığı (KOAH) </Heading>
                </Level>
              </Level>
              <p>
                KOAH, akciğer ve alt solunum yollarında uzun süreli hasara neden
                olan bir grup hastalığı içerisinde barındırır. Bu durumların
                ortaya çıkışında tütün kullanımı birinci sırada yer alan etken
                olması açısından önemlidir. Sigara içen bireylerde akciğerlerde
                biriken mukus, öksürük refleksinin çok sık tetiklenmesine neden
                olabilir. Vücuttan atılan balgamın rengi bu kişilerde yeşil,
                sarı veya kanlı olarak tespit edilebilir.
              </p>
              <Level>
                <Level>
                  <Heading>Kistik Fibrozis </Heading>
                </Level>
              </Level>
              <p>
                Kistik fibrozis, genellikle çocukluk döneminde tespit edilen
                ağır seyirli bir genetik rahatsızlıktır. Bu rahatsızlık başta
                solunum yolları olmak üzere çeşitli organlarda üretilen salgının
                daha yoğun bir yapı kazanmasına neden olur. Ülkemizde yenidoğan
                bebeklere bu hastalığın varlığına dair tarama yapılmaktadır.
                Kistik fibrozis rahatsızlığı sırasında oluşan yoğun balgam hava
                yollarını tıkayarak kişinin nefes alıp vermede zorlanmasına
                neden olabilir. Bu yoğun salgı aynı zamanda ortamda bulunan
                bakterilerin üremesi için elverişli bir özellik gösterir. Kistik
                fibrozis hastalarında bakteri üremesinin kolaylaşması bu
                bireylerin sık olarak solunum yolu hastalıklarına yakalanmasına
                sebep olur.
              </p>
              <Level>
                <Level>
                  <Heading>Astım</Heading>
                </Level>
              </Level>
              <p>
                Astım hastalarının genelinde ortaya çıkan öksürük tipi, kuru
                öksürüktür. Ancak bazı astım hastalarında aşırı mukus salgısı
                üretimi ve kronik prodüktif öksürük oluşumu meydana gelebilir.
                Küçük çocuklarda tespit edilen balgamlı öksürüğün temel sebebi
                viral hastalıklardır. İkinci sırada ise astım yer alır. Astımı
                olan kişilerde hava yolları alerjenlere, havada bulunan
                partiküllere ve solunum yolu enfeksiyonlarına karşı hassas
                özelliktedir. Bu hassasiyet hava yollarında inflamasyon
                (iltihaplanma) gelişimini tetikleyebilir ve dolayısıyla mukus
                salgısının artmasına neden olarak balgam gelişimi ile
                sonuçlanabilir.
              </p>
              <p />
            </Level>
          </section>
          <section id="balgam-nasil-atilir" className={styles.typesHolder}>
            <Level>
              <Level>
                <Heading>Balgam Nasıl Atılır?</Heading>
              </Level>
              <p>
                Balgam, hava yollarının doğal koruyucu reflekslerinden biri olsa
                da günlük yaşamı zorlaştıracak düzeyde rahatsız edici boyuta
                vardığında hekimlerinizin bilgisi ve önerisi dahilinde
                yapabileceğiniz çeşitli uygulamalar bu şikayetinizin
                giderilmesinde fayda sağlayabilir:
              </p>
              <ul>
                <li className="blue">
                  Kuru hava burundan başlayarak solunum yolları boyunca tahrişe
                  neden olur ve bu yapılarda mukus salgısının daha fazla
                  üretilmesini tetikler. Sıcak buhar çekilmesinin sanıldığı
                  kadar masum bir uygulama olmadığı unutulmamalıdır. Bu durum
                  yerine yaşanılan ortamı ılık bir şekilde nemlendirilmesini
                  sağlayan cihazlar solunum yollarının nemlendirilmesi dışında
                  daha iyi bir uyku kalitesi de sağlayabilir.
                </li>
                <li className="blue">
                  Mukusun az yoğun olması içeriğinde su düzeyinin yüksek olması
                  ile mümkündür. Dolayısıyla günlük tüketilen sıvı miktarının
                  artırılması mukus salgısının daha akışkan bir hale gelerek
                  sinüslerden uzaklaştırılmasını destekler.
                </li>
                <li className="blue">
                  Yüze ılık havlu uygulanması özellikle sinüslerde doluluk
                  nedeniyle baş ağrısı şikayeti bulunan kişilerde etkili bir
                  yaklaşım olabilir.
                </li>
                <li className="blue">
                  Yüz bölgesindeki sinüslerde mukus birikimi rahatsız edici bir
                  durumdur. Uyurken kullanılan yastık sayısının artırılarak
                  başın yüksekte tutulması bu bölgedeki mukusun aşağı kısımlara
                  doğru akışını kolaylaştırıcı özellik gösterebilir.
                </li>
                <li className="blue">
                  Tuzlu su ile gargara yapmak boğazdaki tahriş hissinin
                  rahatlamasına ve bu bölgede biriken balgamın
                  uzaklaştırılmasına katkı sağlayabilir. Bu uygulamayı
                  gerçekleştirmek için bir bardak sıcak suya 0,5-1 çay kaşığı
                  tuz dökerek bu sıvı ile günde birkaç kez gargara yapmak
                  yeterli olacaktır.
                </li>
                <li className="blue">
                  Tütün kullanımı ve pasif içicilik vücutta üretilen mukus
                  salgısı miktarının artmasına neden olur. Dolayısıyla hem bu
                  zararlı alışkanlığı bırakmak hem de kullanıldığı ortamlardan
                  uzak durmak balgam şikayetinin hafifletilmesinde etkili
                  olabilir.
                </li>
                <li className="blue">
                  Mevsimsel alerjisi olan kişiler bu dönemlerde hekimleri
                  tarafından reçetilendirilen ilaçları kullanarak solunum
                  yollarında aşırı reaksiyon nedeniyle oluşabilecek balgam
                  şikayetine karşı önlem alabilirler.
                </li>
                <li className="blue">
                  Reflü şikayeti bulunan kişilerde mide asidinin yukarıya doğru
                  yaptığı hareket sonucunda üst solunum yollarında üretilen
                  mukus salgısında artış meydana gelebilir. Dolayısıyla reflü ve
                  mide yanması gibi şikayeti bulunan kişilerin sağlık
                  kuruluşlarına başvurarak altta yatan durumun nasıl kontrol
                  altına alınabileceğine dair hekimlerine danışması önerilir.
                </li>
              </ul>
              <p>
                Herhangi bir uygulama yapılırken öksürüğün koruyucu bir
                mekanizma olduğu unutulmamalıdır. Öksürüğü tamamen ortadan
                kaldıracak aşırı ilaç kullanımından kaçınılması oldukça
                önemlidir.
              </p>
              <StaticImage
                className={styles.image}
                src="../../../images/cozum/balgam-nasil-atilir/ill-millenial-lady-holding-mug-and-cleaning-nose-w-2021-09-01-15-41-30-utc.webp"
                alt="Burununu silen kadın görseli"
                width={792}
                height={430}
              />
            </Level>
          </section>

          <section className={styles.kidsHolder}>
            <Level>
              <Level>
                <Heading>
                  Bisolnatur ile Kuru ve Balgamlı Öksürüğünü Rahatlat{" "}
                </Heading>
                <div className={classNames("flexbox", styles.productHolder)}>
                  <StaticImage
                    className={styles.image}
                    src="../../../images/product-bar-bisolduo-surup.png"
                    alt="Bisolnatur ürün görseli"
                    width={282}
                    height={282}
                  />
                  <div className={styles.content}>
                    <p>
                      Doğal içerikli Bisolnatur öksürük şurubu hem kuru hem de
                      prodüktif öksürük şikayeti olan bireylerde balgamın
                      vücuttan uzaklaştırılmasını destekleyici özellik gösterir.
                      Balgamın oluşumundaki temel mekanizmalardan biri olan hava
                      yollarının iç çeperinde inflamasyon veya tahriş meydana
                      gelmesine karşı Bisolnatur öksürük şurubu bu bölgedeki
                      mukozal tabakanın nemlenmesini destekler ve vücudun
                      fizyolojik (doğal) mekanizmaları ile uzaklaştırılmasını
                      kolaylaştırır. Bisolnatur öksürük şurubu aynı zamanda
                      mukozal tabaka üzerinde koruyucu bir film oluşturarak bu
                      bölgede tahrişten sorumlu maddeler ile mukozal tabaka
                      arasında bir bariyer özelliği gösterir.
                    </p>
                  </div>
                </div>
                <p>
                  Bisolnatur öksürük şurubu içerisinde doğal şeftali, limon ve
                  portakal aromalarına ek olarak poliflav m.a. kompleksi ve bal
                  yer almaktadır. Polifilav M.A kompleksi latince “plantago
                  lanceolata” olarak ifade edilen mızrak yapraklı sinir otundan
                  elde edilir. Bu kompleksin içerisinde aynı zamanda kekik
                  bitkisi içerisinde bulunan flavonoid yapıda maddeler de yer
                  alır. Dar (mızrak) yapraklı sinir otu poleni üzerinde yapılan
                  araştırmalar bu bitkinin 4000 yıl önce Kuzey Avrupa
                  bölgelerinde kullanıldığını göstermiştir. Bu bitki zaman
                  içerisinde Avrupa’dan tüm dünyaya insan eli vasıtasıyla
                  yayılmıştır. Sinir otu bitkisi yerliler tarafından “beyaz
                  adamın ayak izi olarak” nitelendirilmiştir. İsveç ve Norveç
                  gibi kuzey ülkelerinde bu bitkinin ismi “groblad” olarak
                  bilinir. Bu ismin kelime anlamının “iyileştirici yapraklar”
                  olması oldukça dikkat çekicidir.
                </p>
                <p>
                  Poliflav M.A. kompleksine ek olarak Bisolnatur öksürük
                  şuruplarının içerisinde solunum yolu salgılarına karşı
                  (afinitesi) etkileşimi bulunan bal da yer alır. Doğal içerikli
                  Bisolnatur öksürük şurubu içerisinde bulunan maddeler kadar
                  bulunmayanlar da oldukça önemlidir.Koruyucu, renklendirici,
                  paraben, gluten ve tatlandırıcı içermeyen Bisolnatur öksürük
                  şurubu “soğuk algınlığına bağlı balgam nasıl atılır?” sorusuna
                  yanıt arayan kişilere boğazdan geçen balgamı nemlendirip
                  yumuşatarak kolaylıkla atılmasına destek olur. Doğal içerikli
                  Bisolnatur öksürük şurubu tüketiciler tarafından tolerasyonu
                  yüksek bir üründür ve 1 yaşından büyük çocuklar veya
                  yetişkinler tarafından kullanılması uygundur. Bisolnatur
                  öksürük şurubunu kullanmadan önce etiketinin iyi incelenmesi
                  ve içerisinde yer alan maddelere dikkat edilmesi oldukça
                  önemlidir. Eğer ürünün içerisinde yer alan herhangi bir
                  maddeye karşı alerjiniz mevcut ise bu ürünü kullanmamanız
                  önerilir. Sizin ve sevdiklerinizin güvenle kullanabileceği
                  doğal Bisolnatur öksürük şurubunu şişenin açılmasını takiben 3
                  ay içerisinde tüketmeniz tavsiye edilir.
                </p>
                <p>
                  Doğal içerikli Bisolnatur öksürük şurubu kutusunun içerisinde
                  yer alan kaşığın ölçüsü 5 ml’dir. 1-6 yaş aralığındaki
                  çocuklar günde iki kez olmak üzere doz başına 1 tatlı kaşığı(5
                  ml) tüketebilir. 6 yaşın üzerindeki çocuklar günde iki kez
                  olmak üzere doz başına 2 tatlı kaşığı(10 ml) tüketilebilir.
                  Yetişkinler ise, günde iki ya da üç kez doz başına 2 tatlı
                  kaşığı (10 ml) tüketebilir; gerektiğinde, ürün kısa
                  aralıklarla günde 4 defaya kadar kullanılabilir.
                  <br />
                  <br />
                </p>
              </Level>
            </Level>
          </section>
          <section className={styles.kaynakca}>
            <Level>
              <Level>
                <Heading id="kaynakca">Kaynakça</Heading>
              </Level>
            </Level>
            <p>
              1- https://www.healthline.com/health/coughing-up-white-mucus
            </p>
            <p>
              2- https://www.healthline.com/health/blood-tinged-sputum
            </p>
            <p>
              3- https://www.healthline.com/health/wet-cough
            </p>
            <p>
              4- https://www.medicalnewstoday.com/articles/318924
            </p>
            <p>
              5- https://www.medicalnewstoday.com/articles/321134
            </p>
            <p>
              6-
              https://www.healthline.com/health/cold-flu/how-to-get-rid-of-phlegm
            </p>
            <p>
              7- https://www.bisolnatur.com.tr/urunler/bisolnatur/
            </p>
            <p>
              8- https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7142308/
            </p>
            <p>
              9- https://pubmed.ncbi.nlm.nih.gov/29028587/
            </p>
          </section>
        </div>
        <div className="date">
          <p>Yayınlama Tarihi: 2022-01-27</p>
          <p>Son güncellenme Tarihi: 2022-01-27</p>
        </div>
      </Container>
      <ProductBar />
    </Layout>
  );
};
export default CoughPage;
